import {html, LitElement} from 'lit';
import {customElement, property, query, queryAll, state} from 'lit/decorators.js';

import {resetStyles} from '../../ts/css/resets.styles';
import {navigationStyles} from './navigation.styles';

import {AjaxP, PubSub} from '@mamdev/cad.static-next-lib';
import Tracking2 from '../../ts/services/tracking2';
import Viewport from '../../ts/services/viewport';
import type {NavigationData} from './INavigationData';

@customElement('ui-navigation')
export class Navigation extends LitElement {
  static get styles() {
    return [resetStyles, navigationStyles];
  }

  @property({reflect: true}) navigation = '/img/navigation.json';

  @property({attribute: 'brain-zone'})
  brainZone: string;

  @state()
  navItemsLoaded = false;

  @state()
  navLayerShown = false;

  @state()
  navData!: NavigationData;

  @queryAll('.lvlone__item--haslist')
  levelOneNavList: HTMLElement[];

  @queryAll('.lvltwo__item--haslist')
  levelTwoNavList: HTMLElement[];

  @query('.nav')
  nav: HTMLElement;

  @query('.navi')
  navi: HTMLElement;

  @query('.new-header__navtrigger')
  navLayerTrigger: HTMLElement;

  @query('footer')
  footer: HTMLElement;

  @query('.lvlone__list')
  navList: Element;

  connectedCallback() {
    super.connectedCallback();

    Tracking2.bindTrackingExposeHandler(this);

    this.addEventListener('transitionend', this.handleTransitionEnd);

    PubSub.subscribe('newnav:closeNavi', () => {
      this.closeNavigationLayer();
      this.navLayerTrigger.focus();
    });

    PubSub.subscribe('article:bodyClicked', () => {
      this.closeNavigationLayer();
    });

    PubSub.subscribe('nav:opening', () => {
      void this.handleNavOpening();
    });
  }

  disconnectedCallback() {
    this.removeEventListener('transitionend', this.handleTransitionEnd);
  }

  async handleNavOpening() {
    if (!this.navItemsLoaded) {
      // load only when opening to increase the general page performance
      await this.getNavigationJson();

      const trackedElement = this.nav.querySelectorAll('[data-component-path]');
      Tracking2.bindTrackingClickHandler(trackedElement);
    }

    document.body.classList.add('nav-open'); // used by header component
    document.documentElement.classList.add('nav-open'); // additionally add to html because adding only to body doesnt always work
    this.classList.add('open'); // open nav
    this.setupScrollHandlingWithIntersectionObserver();
    this.scrollToFocusElementOnDesktop();

    setTimeout(() => {
      this.classList.add('nav-ani'); // show layer with animation
      this.navLayerShown = true;
    }, 50); // trigger animation (after nav layer is visible)
  }

  handleTransitionEnd(ev: TransitionEvent) {
    if (ev.propertyName === 'height') {
      this.duplicateFooter();
    }
  }

  /**
   * Show or hide first level elements
   */
  onExpandSecondLevel() {
    const showCls = 'lvlone__item--show';
    [...this.levelOneNavList].forEach(listItem => {
      listItem.addEventListener('click', (e: Event) => {
        if (e.target && !(e.target as HTMLElement).classList.contains('lvlone__icon')) return;
        if (listItem.classList.contains(showCls)) {
          listItem.classList.remove(showCls);
        } else {
          this.collapseAllElementsOfLevel(1);
          listItem.classList.add(showCls);
        }
      });
    });
  }

  /**
   * Show or hide second level elements
   */
  onExpandThirdLevel() {
    const showCls = 'lvltwo__item--show';
    [...this.levelTwoNavList].forEach(listItem => {
      listItem.addEventListener('click', (e: Event) => {
        if (e.target && !(e.target as HTMLElement).classList.contains('lvltwo__icon')) return;
        if (listItem.classList.contains(showCls)) {
          listItem.classList.remove(showCls);
        } else {
          this.collapseAllElementsOfLevel(2);
          listItem.classList.add(showCls);
        }
      });
    });
  }

  duplicateFooter() {
    // Footer already duplicated?
    if (this.footer) {
      console.log('footer already exists');
      return;
    }

    const footerElem = document.querySelector('.left-col footer');
    if (footerElem) {
      const dupFooterElem = footerElem.cloneNode(true);
      this.nav.appendChild(dupFooterElem);
    }
  }

  closeNavigationLayer() {
    if (!this.navLayerShown) {
      return;
    }

    this.classList.remove('nav-ani'); // hide layer without animation
    this.classList.remove('open');
    PubSub.publish('newnav:closeNavLayer', {});
  }

  async getNavigationJson() {
    this.navData = await AjaxP.getJSON(this.navigation);

    // wait till the ui is loaded
    await this.updateComplete;

    // bind menu collapse events
    this.onExpandSecondLevel();
    this.onExpandThirdLevel();

    // brain tracking
    PubSub.publish('navigation:ready', this.navList);

    this.navItemsLoaded = true;
  }

  setupScrollHandlingWithIntersectionObserver() {
    const cls = 'attop';
    const firstRealNavItem = this.navi.getElementsByClassName('lvlone__item')[1];
    const opts = {
      root: this.navi
    };

    if ('IntersectionObserver' in window) {
      const scrollOb = new IntersectionObserver(entries => {
        if (!entries[0].isIntersecting) {
          this.navList.classList.remove(cls);
        } else if (entries[0].intersectionRatio < 0.5) {
          this.navList.classList.add(cls);
        }
      }, opts);

      scrollOb.observe(firstRealNavItem);
    }
  }

  scrollToFocusElementOnDesktop() {
    let shouldScroll = true;

    if (!Viewport.isDesktop()) {
      // Non-desktop view will scroll on all pages but homepage
      if (document.body.classList.contains('homepage')) {
        shouldScroll = false;
      }
    }

    if (shouldScroll) {
      const focusElement = this.navi.getElementsByClassName('lvlone__item--show')[0] as HTMLElement;
      if (focusElement) {
        this.navi.scrollTop = focusElement.offsetTop - 17;
      }
    }
  }

  collapseAllElementsOfLevel(lvl: number) {
    const showCls = lvl == 1 ? 'lvlone__item--show' : 'lvltwo__item--show';
    const itemWithList = this.navi.getElementsByClassName(showCls)[0];
    if (itemWithList) {
      itemWithList.classList.remove(showCls);
    }
  }

  trackBrainZone(href: string, brainData: string | null) {
    PubSub.publish('navigation:brainZone', {
      destination: href,
      brainData: brainData
    });
  }

  get componentPath() {
    return this.getAttribute('data-component-path') ?? '';
  }

  render() {
    return html`
      <!-- Navigation Layer -->
      <nav class="nav">
        <div class="nav__header">
          <div class="new-header__hamburger">
            <label class="new-header__navtrigger" tabindex="0" @click=${this.closeNavigationLayer}>
              <span class="burger-icon"></span>
            </label>
          </div>
          <div class="new-header__logo" id="organization" itemprop="publisher">
            <a
              class="logo-link"
              aria-label="Zurück zur web.de Startseite"
              href="/"
              data-brain-zone="${this.brainZone}"
              data-component-path="${this.componentPath}"
              tabindex="0"
            >
              <span class="logo-image"></span>
            </a>
          </div>
        </div>

        <div class="navi">
          <ul class="lvlone__list innernav">
            <li class="lvlone__item topline"></li>
            ${this.navData?.navlist.map((navItem: NavigationData) => {
              return html`
                <li class="lvlone__item
                    ${navItem.navlist ? 'lvlone__item--haslist' : null}
                    ${navItem.focus ? 'lvlone__item--show' : null}"
                >
                  <div class="lvltwo__header">
                    <span class="header-spacer">
                      <a
                        href="${navItem.href}"
                        tabindex="0"
                        data-brain-zone="${navItem.dataBrainZone}"
                        data-component-path="${navItem.componentPath}"
                      >${navItem.name}</a
                      >
                    </span>
                    <span class="lvlone__icon icon icon-arrow-down"></span>
                  </div>
                  ${navItem.navlist ? html`
                    <ul class="lvltwo__list">
                      ${navItem.navlist.map((navtwoItem: NavigationData) => html`
                        <li
                          class="lvltwo__item ${navtwoItem.navlist ? 'lvltwo__item--haslist' : null}"
                        >
                          <div class="lvlthree__header">
                            <span class="header-spacer">
                              <a
                                href="${navtwoItem.href}"
                                tabindex="0"
                                data-brain-zone="${navtwoItem.dataBrainZone}"
                                data-component-path="${navtwoItem.componentPath}"
                              >
                                ${navtwoItem.name}
                              </a>
                            </span>
                            <span
                              class="lvltwo__icon icon icon-arrow-down"
                            ></span>
                          </div>
                          ${navtwoItem.navlist ? html`
                            <ul class="lvlthree__list">
                              ${navtwoItem.navlist.map((navthreeItem: NavigationData) => html`
                                <li class="lvlthree__item">
                                  <span class="header-spacer">
                                    <a
                                      href="${navthreeItem.href}"
                                      tabindex="0"
                                      data-brain-zone="${navthreeItem.dataBrainZone}"
                                      data-component-path="${navthreeItem.componentPath}"

                                    >${navthreeItem.name}</a
                                    >
                                  </span>
                                </li>
                              `)}
                              ${navtwoItem.partneroffers ? html`
                                <li
                                  class="lvlthree__item lvlthree__item--partneroffers"
                                >
                                  Partnerangebote
                                </li>
                                ${navtwoItem.partneroffers.map((navthreeItem: NavigationData) => html`
                                  <li
                                    class="lvlthree__item"
                                  >
                                    <span class="header-spacer">
                                      <a
                                        href="${navthreeItem.href}"
                                        tabindex="0"
                                        data-brain-zone="${navthreeItem.dataBrainZone}"
                                        data-component-path="${navthreeItem.componentPath}"
                                      >${navthreeItem.name}</a
                                      >
                                    </span>
                                  </li>`)}
                              ` : null}
                            </ul>` : null}
                        </li>
                      `)}
                    </ul>` : null}
                </li>
              `;
            })}
          </ul>
        </div>
      </nav>
    `;
  }
}
